import { useEffect, useState } from 'react'
import InputHelper from '@qtc-repo/ui/components/Form/InputHelper'
import { SelectChangeEvent } from '@mui/material/Select'
import { DatePickerProps } from './type'
import dynamic from 'next/dynamic'

const MobileDateField = dynamic(() => import('./MobileDateField'), { ssr: true })

const MuiSelect = dynamic(() => import('../Select/MuiSelect'), { ssr: true })

const DatePicker = ({
  onChange,
  minYear,
  maxYear,
  value,
  firstEvents,
  handleFieldError,
  readOnly,
}: DatePickerProps) => {
  const [dates, setDates] = useState<string[]>([])
  const Months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const [years, setYears] = useState<string[]>([])
  const [inputFocused, setInputFocused] = useState({
    day: false,
    month: false,
    year: false,
  })
  const handleMUISelectChange = (e: SelectChangeEvent) => {
    onChange(e.target.name as 'day' | 'year' | 'month', e.target.value)
  }
  useEffect(() => {
    const _dates = []
    for (let d = 1; d <= 31; d++) {
      _dates.push(('0' + d).slice(-2))
    }
    setDates(_dates)
    const _years = []
    for (let y = maxYear; y >= minYear; y--) {
      _years.push(y.toString())
    }
    setYears(_years)
  }, [maxYear, minYear])

  const valid_year = value?.year && +value?.year >= minYear && +value?.year <= maxYear
  const valid = value?.day && value?.month && valid_year
  useEffect(() => {
    handleFieldError(!valid)
  }, [valid])

  return (
    <>
      <div
        className={`mb-2 w-full ${
          firstEvents?.day || firstEvents?.month || firstEvents?.year
            ? ''
            : value?.day && value?.month && valid_year
              ? 'success'
              : 'error'
        }`}
      >
        <label htmlFor="birthday" className="block text-lg font-bold text-gray-900 dark:text-white">
          Date of birth
        </label>
      </div>
      <div className={'col-span-2 w-full sm:hidden'}>
        <MobileDateField
          firstEvents={firstEvents}
          onChange={onChange}
          maxYear={maxYear}
          minYear={minYear}
          handleFieldError={handleFieldError}
          value={value}
          readOnly={readOnly}
        />
      </div>
      <div id="birthday" className="hidden gap-5 sm:grid sm:grid-cols-3">
        <div className="grid grid-cols-2 gap-5 sm:col-span-2">
          <div>
            <MuiSelect
              id="day"
              name="day"
              value={value?.day}
              onChange={handleMUISelectChange}
              onFocus={() => setInputFocused(prev => ({ ...prev, day: true }))}
              onBlur={() => setInputFocused({ ...inputFocused, day: false })}
              readOnly={readOnly}
              error={!firstEvents.day && !inputFocused.day && !value?.day}
              success={!inputFocused.day && !!value?.day}
              options={dates.map(date => ({ label: date, value: date }))}
            />
            {!value?.day && !firstEvents?.day && !inputFocused?.day ? (
              <InputHelper text="Select day" error />
            ) : (
              <InputHelper text="Day" />
            )}
          </div>
          <div>
            <MuiSelect
              id="month"
              name="month"
              value={value?.month}
              onChange={handleMUISelectChange}
              onFocus={() => setInputFocused(prev => ({ ...prev, month: true }))}
              onBlur={() => setInputFocused({ ...inputFocused, month: false })}
              readOnly={readOnly}
              error={!firstEvents.month && !inputFocused.month && !value?.month}
              success={!inputFocused.month && !!value?.month}
              options={Months.map((month, index) => ({ label: month, value: ('0' + (index + 1)).slice(-2) }))}
            />
            {!value?.month && !firstEvents?.month && !inputFocused?.month ? (
              <InputHelper text="Select month" error />
            ) : (
              <InputHelper text="Month" />
            )}
          </div>
        </div>
        <div>
          <MuiSelect
            id="year"
            name="year"
            value={+value?.year < minYear || +value?.year > maxYear ? '' : value?.year}
            onChange={handleMUISelectChange}
            onFocus={() => setInputFocused(prev => ({ ...prev, year: true }))}
            onBlur={() => setInputFocused({ ...inputFocused, year: false })}
            readOnly={readOnly}
            error={!firstEvents.year && !inputFocused.year && !value?.year}
            success={!inputFocused.year && !!value?.year}
            options={years.map(year => ({ label: year, value: year }))}
          />
          {!value?.year && !firstEvents?.year && !inputFocused?.year ? (
            <InputHelper text="Select year" error />
          ) : (
            <InputHelper text="Year" />
          )}
        </div>
      </div>
    </>
  )
}
export default DatePicker
